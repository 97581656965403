import React from 'react';
import { Fragment, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';

import i18next from "./i18n/init";
import Language from "./components/language/Language";

import Home from './pages/Home';

function App() {
  return (
    <Fragment>
      <Suspense fallback={null}>
        <Home />
        <Language />
      </Suspense>
    </Fragment>
  );
}

export default App;
