import React from "react";
import { Fragment } from "react";
import i18next from "i18next";

// import StellarSpace from "./Blockchain/StellarSpace";

import classes from "../HomeCodingContents.module.css";
function Watakura(props) {
  return (
    <Fragment>
      <ul>
        <li>
          <div>
            <a
              href="https://watakura.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>watakura</strong>
            </a>
          </div>
          <div>
            {i18next.t(
              "homeText10",
              "Media (photos, videos) sharing and storage, text talk, group text talk"
            )}.
            {' '}
          </div>
          {/* <div>
            It is possible to create your site or blog at
            {' '}
            <a
              href="https://wpsite.watakura.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>watakura your site</strong>
            </a>
          </div> */}
        </li>
        {/* <li>
          <div>
            <a
              href="https://wpsite.watakura.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>watakura your site</strong>
            </a>
          </div>
          <div>
            watakura your site enables to create your site or blog.
          </div>
        </li> */}
      </ul>
    </Fragment>
  );
}

export default Watakura;
