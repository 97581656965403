import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import { marks } from "../../../../utils/marks";

// import classes from "../HomeCodingContents.module.css";
import classes from "./KuraStorage.module.css";

const textAudioToolUrl = "https://kura-text-audio-tools.spaceeight.net";

function TextAudioTools(props) {
  //   const [showDetail, setShowDetail] = useState(false);

  return (
    <Fragment>
      <div>
        <a
          href={`${textAudioToolUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
            <strong>Text Audio Tools</strong>
        </a>
      </div>
      <p>
        Kura Text Audio Tools 
        {' '}
        {i18next.t('textAudioTools01', 'provide tools for handling text and audio data.')}
        {i18next.t('textAudioTools02', 'The tools inclede')}
        {' '}
        Audio Transcriber, Voice Generator, Answer Finder.
      </p>
      <p>
        In Audio transcriber, 
        {' '}
        {i18next.t('textAudioTools03', 'user can generate text transcript from audio sources such as audio file, video file, and recorded voices.')} 
        {' '}
        {i18next.t('textAudioTools04', 'It is possible to choose a language for generating text from more than 90 different languages.')}
      </p>
      <p>
        In Voice generator, 
        {' '}
        {i18next.t('textAudioTools05', 'user can generate audio from text input.')}
        {' '} 
        {i18next.t('textAudioTools06', 'User can control output audio speed and select voices from more than 900 different voices.')}
      </p>
      <p>
        In Answer Finder,
        {' '}
        {i18next.t('textAudioTools07', 'User can find an answer in context text by asking question.')}
      </p>
    </Fragment>
  );
}

export default TextAudioTools;
