import React from "react";
import { Fragment } from "react";
import i18next from "i18next";

import KuraStorage from "./KuraStorage/KuraStorage";
import StellarSpace from "./Blockchain/StellarSpace";
import Watakura from "./Watakura/Watakura";
import Remeet from "./Remeet/Remeet";
import AuthSso from "./AuthSso/AuthSso";
import Adnetwork from "./Adnetwork/Adnetwork";

import classes from "./HomeCodingContents.module.css";
import StellarNftPlace from "./Blockchain/StellarNftPlace";

import StellarBlockchain from "./Blockchain/StellarBlockchain";

function HomeProgramingContents(props) {
  return (
    <Fragment>
      {/* <span>stsp, wata, remeet, auth, ...</span> */}
      <Watakura />
      <Remeet />
      <StellarBlockchain />
      <Adnetwork />
      <KuraStorage />
      <AuthSso />
      <ul>
        <li>...</li>
      </ul>
    </Fragment>
  );
}

export default HomeProgramingContents;
