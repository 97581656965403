import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import { marks } from "../../../../utils/marks";

// import classes from "../HomeCodingContents.module.css";
import classes from './Adnetwork.module.css';

function Adnetwork(props) {

  const [showDetail, setShowDetail] = useState(false);

  return (
    <Fragment>
      <ul>
        <li className={classes.adnetworkDetailButton}
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          <strong>
            Adnetwork
            {/* {' '}
            (show detail {" "}
            <span>
              {marks.downArrowMark}
            </span>
            ) */}
          </strong>
        </li>

        <ul>
          <li>
            <p>
              Adnetwork
              {' '}
              {i18next.t("adnetwork01", "provides services for ad creation and displaying ads.")}
            </p>
            <p className={classes.adnetworkDetailButton}
              onClick={() => {
                setShowDetail(!showDetail);
              }}>
              <strong>
                (show detail {" "}
                <span>
                  {/* <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg> */}
                  {marks.downArrowMark}
                </span>
                )
              </strong>
            </p>

          </li>
          {showDetail && (
            <div>
              <li>
                <div>
                  <a
                    href="https://adnetwork.spaceeight.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Adnetwork</strong>
                  </a>
                </div>
                <p>
                  {i18next.t("adnetwork02", "Create ads to reach your specified targets, such as language, geolocation, target devices.")}
                </p>
                <p>
                  {i18next.t("adnetwork03", "It is possble to create image banner ad, video ad, and text ad for displaying them in various web sites.")}
                </p>
              </li>
              <br />
              <li>
                <div>
                  <a
                    href="https://adnetwork-adhost.spaceeight.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Adnetwork Ad host</strong>
                  </a>
                </div>
                <p>
                  Adnetwork Ad host
                  {' '}
                  {i18next.t("adnetwork04", "enables you to display various types of ads in your website.")}
                </p>
                <p></p>
              </li>
              <br />
            </div>
          )}
        </ul>
      </ul>
      <br />
    </Fragment>
  );
}

export default Adnetwork;
