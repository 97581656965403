import React from "react";
import { Fragment } from "react";
import i18next from "i18next";


import classes from "../HomeCodingContents.module.css";
function Remeet(props) {
  return (
    <Fragment>
      <ul>
        <li>
          <div>
            <a
              href="https://remeet.watakura.xyz/your-new-room-from-above-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>REMEET</strong>
            </a>
          </div>
          <div>
            {i18next.t(
              "homeText11",
              "Video talk and web video meetings with text chat, screen sharing, and file sharing"
            )}
          </div>
        </li>
        <li>
          <div>
            <a
              href="https://remeet-wepl.watakura.xyz/your-presentatin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>REMEET-WePL</strong>
            </a>
          </div>
          <div>
            <div>
              {i18next.t(
                "homeText12",
                "Simple video presentation app that includes text chat, screen share, and file share."
              )}
            </div>
            <div>
              {i18next.t(
                "homeText13",
                "Live broadcasting enables to show video talk and presentatin to more audiences at"
              )}
              {' '}
              <a
                href="https://watakura.xyz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>watakura</strong>
              </a>
            </div>
          </div>
        </li>        
      </ul>
      <br/>
    </Fragment>
  );
}

export default Remeet;
