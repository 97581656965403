import React from "react";
import { Fragment } from "react";
import i18next from "i18next";

import classes from "../HomeCodingContents.module.css";

function StellarNftPlace(props) {
  return (
    <Fragment>
      {/* <span>stsp, wata, remeet, auth, ...</span> */}
      <ul>
        <li>
          <div>
            <a
              href="http://stellar-nftplace.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Stellar NFT Place</strong>
            </a>
          </div>
          <div>
            {i18next.t("blockchain07", "NFT Platform using blockchain based")}
            {" "}
            Stellar network.
            {' '}
            {i18next.t("blockchain08", "Create, trade, display NFTs.")}
          </div>
          <div>
          </div>
        </li>
      </ul>
    </Fragment>
  );
}

export default StellarNftPlace;
