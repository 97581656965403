import React from "react";
import { Fragment } from "react";
import i18next from "i18next";

import classes from "../HomeCodingContents.module.css";

function StellarSpace(props) {
  return (
    <Fragment>
      {/* <span>stsp, wata, remeet, auth, ...</span> */}
      <ul>
        <li>
          <div>
            <a
              href="http://www.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>StellarSpace</strong>
            </a>
          </div>
          <div>
            {i18next.t(
              "homeText6",
              "Decentralized Exchange using blockchain based"
            )}
            {" "}
            Stellar network. 
            {' '}
            {i18next.t(
              "blockchain10","Access to liquidity pool share to provide liquidity and get rewards."
            )}
          </div>
          {/* <div>
            <span>{i18next.t("homeText7", "Website link")}: </span>{" "}
            <a
              href="http://app.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18next.t("homeText8", "mobile")}
            </a>{" "}
            <a
              href="http://www.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18next.t("homeText9", "desktop")}
            </a>
          </div> */}
        </li>
        <li>
          <div>
            <a
              href="http://news.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>StellarSpace News</strong>
            </a>
          </div>
          <div>
            StellarSpace News 
            {' '}
            {i18next.t("blockchain05", "provides news from various news sources about cryptocurrencies, markets, blockchain, others, and reward points info.")}
          </div>
        </li>
        <li>
          <div>
            <a
              href="http://deposit.spaceeight.work"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>StellarSpace Deposit</strong>
            </a>
          </div>
          <div>
            At StellarSpace Deposit, 
            {' '}
            {i18next.t("blockchain06", "it is possible to buy and deposit XLM to your account using your credit card.")}          
          </div>
        </li>
        <li>
          <div>
            <a
              href="https://blend-ui-supply.vercel.app/about-page"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Interest Earn</strong>
            </a>
          </div>
          <div>
            At Interest Earn, 
            {' '}
            {i18next.t("blockchain11","user can provide assets to lending pools and receive interest in return.")}
          </div>
        </li>
      </ul>
    </Fragment>
  );
}

export default StellarSpace;
