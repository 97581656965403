import React from "react";
import { Fragment } from "react";
import i18next from "i18next";


import classes from "../HomeCodingContents.module.css";
function AuthSso(props) {
  return (
    <Fragment>
      <ul>
      <li>
          <div>
            <strong>Auth for other services</strong>
          </div>
          <div>
            {i18next.t(
              "homeText14",
              "Provide user authentication for other services including"
            )}
            {" "}
            <a
              href="https://watakura.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              watakura
            </a>
            ,{" "}
            <a
              href="https://bookremeet.spaceeight.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              REMEET
            </a>
            , Adnetwork services, and Kura Storage Services.
          </div>
        </li>
      </ul>
    </Fragment>
  );
}

export default AuthSso;
