import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import KuraImagePhoto from "./KuraImagePhoto";
import TextAudioTools from "./TextAudioTools";

import { marks } from "../../../../utils/marks";

// import classes from "../HomeCodingContents.module.css";
import classes from "./KuraStorage.module.css";

function KuraStorage(props) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <Fragment>
      <ul>
        <li
          className={classes.kuraStorageDetailButton}
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          <strong>
            Kura Storage Services
            {/* (show detail {" "}
            <span>
              {marks.downArrowMark}
            </span>
            ) */}
          </strong>
        </li>
        <ul>
          <li>
            <p>
              Kura Storage Services
              {" "}
              {i18next.t("kuraStorage27","provide storage space for various files.")}
              {" "}
              {i18next.t("kuraStorage02", "The services include")}{" "}
              <a
                href="https://kura-storage.spaceeight.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Kura Storage</strong>
              </a>
              ,{" "}
              <a
                href="https://kura-image-photo.spaceeight.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Kura Image Photo</strong>
              </a>
              .
            </p>
            <p>
              {i18next.t(
                "kuraStorage03",
                "There is free storage space to upload files."
              )}{" "}
              {i18next.t(
                "kuraStorage04",
                "It is possibe to access files from both mobile devices and desktop devices."
              )}
            </p>
            <p>
              {i18next.t("kuraStorage05", "By using simple tools at")}{" "}
              <a
                href="https://kura-storage-tool.spaceeight.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kura Storage Tools
              </a>
              ,{" "}
              {i18next.t(
                "kuraStorage06",
                "document, spreadsheet, and slide presentation files can be created."
              )}
            </p>
            <p>Kura Image Photo 
              {' '}
              {i18next.t(
                "kuraStorage28",
                "has various tools for image editing."
              )}
              {' '}
              {i18next.t(
                "kuraStorage30",
                "It has image generation tools, and image editing tools using image generation functionalities as well."
              )}
            </p>
            <p>
              <strong
                className={classes.kuraStorageDetailButton}
                onClick={() => {
                  setShowDetail(!showDetail);
                }}
              >
                (show detail{" "}
                <span>
                  {/* <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg> */}
                  {marks.downArrowMark}
                </span>
                )
              </strong>
            </p>
          </li>

          {showDetail && (
            <div>
              <li>
                <div>
                  <a
                    href="https://kura-storage.spaceeight.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Kura Storage</strong>
                  </a>
                </div>
                <div>
                  <p>
                    Kura Storage{" "}
                    {i18next.t(
                      "kuraStorage07",
                      "is simple storage place for your files."
                    )}{" "}
                    {i18next.t(
                      "kuraStorage08",
                      "You can upload, store, access, and download files."
                    )}
                  </p>
                  <p>
                    {i18next.t(
                      "kuraStorage09",
                      "It is possible to edit uploaded Word file or Excel file using"
                    )}{" "}
                    <a
                      href="https://kura-storage-tool.spaceeight.net/text-editor"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18next.t("kuraStorage10", "text editor")}
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://kura-storage-tool.spaceeight.net/spreadsheet"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18next.t("kuraStorage11", "spreadsheet")}
                    </a>
                    .
                    <br />
                    {i18next.t(
                      "kuraStorage12",
                      "By using a simple tool for slide presentation at"
                    )}{" "}
                    <a
                      href="https://kura-storage-tool.spaceeight.net"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kura Storage tools
                    </a>
                    ,{" "}
                    {i18next.t(
                      "kuraStorage13",
                      "it is possible to make files for slide presentation."
                    )}
                  </p>
                </div>
              </li>
              <li>
                <TextAudioTools/>
              </li>
              <li>
                <KuraImagePhoto />
              </li>
              <br />
            </div>
          )}
        </ul>
      </ul>
      <br />
    </Fragment>
  );
}

export default KuraStorage;
