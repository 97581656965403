import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import HomeProgrammingContents from "../components/home/HomeContents/HomeCodingContents";

import { marks } from "../utils/marks";
import classes from "./Home.module.css";

function Home(props) {
  // console.log('i18next in Home.js', i18next);

  const [showProgrammingContents, setShowProgrammingContents] = useState(false);

  return (
    <Fragment>
      <h1>SpaceEight</h1>
      <section className={classes.homeBody}>
        <h3>{i18next.t('homeText1', 'Activity')}</h3>
        <ul>
          <li>
            <strong>{i18next.t('homeText2', 'asset management')}</strong>
          </li>
          <li
            className={classes.homeExpand}
            onClick={() => {
              setShowProgrammingContents(!showProgrammingContents);
            }}
          >
            <strong>{i18next.t('homeText3', 'web, programming, contents')}</strong>{" "}
            <strong>
              ({i18next.t('homeText4', 'open')}{" "}
              <span>
                {/* <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg> */}
                {marks.downArrowMark}
              </span>
              )
            </strong>
          </li>
          {showProgrammingContents && <HomeProgrammingContents />}
          <li>
            <strong>{i18next.t('homeText5', 'research, etc...')}</strong>
          </li>
        </ul>
      </section>
      {/* <div style={{textAlign: "center"}}>
        <iframe width="569" height="315" src="https://www.youtube.com/embed/Xt9Hk7zCItM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> */}
    </Fragment>
  );
}

export default Home;
